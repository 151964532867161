import React from "react";
import { ReactComponent as RightArrow } from "../assets/images/Expand_right.svg";

const Card = ({ color, name, faculty, cipher, textColor }) => {
  const titleColor = `${textColor ? `blackText` : ``}`;
  return (
    <section className={`card ${color} `}>
      <h4 className={titleColor}>{name}</h4>
      <h5 className={titleColor}>
        кафедра <br /> {faculty}
      </h5>
      <div className="card__links">
        <button className="card__more">
          <p>детальніше</p>
          <RightArrow />
        </button>
        <div className="card__cipher">{cipher}</div>
      </div>
    </section>
  );
};

export default Card;
