import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as EyesSVG } from "../assets/images/Eyes.svg";

const Developing = () => {
  return (
    <div className="developing">
      <p>
        <span>Дана сторінка у процесі розробки.</span> <EyesSVG />
      </p>
      <span>Вибачте за незручноcті!</span>
      <Link to="/">Повернутись на Головну</Link>
    </div>
  );
};

export default Developing;
