import React from "react";
import { ReactComponent as Folddown } from "../assets/images/Fold_down.svg";

const ScrollDown = ({ title }) => {
  return (
    <div className="scrolldown">
      <div className="chevron"></div>
      <div className="chevron"></div>
      <div className="chevron"></div>
      <p className="text">{title}</p>
    </div>
  );
};

export default ScrollDown;

// import React from "react";
// import { ReactComponent as Folddown } from "../assets/images/Fold_down.svg";

// const ScrollDown = ({ title }) => {
//   return (
//     <div className="scrolldown">
//       <Folddown />
//       <p>{title}</p>
//     </div>
//   );
// };

// export default ScrollDown;
