import React from "react";
import SkeletonCard from "./SkeletonCard";
import Shimmer from "./Shimmer";

const SkeletonCards = () => {
  return (
    <div className="skeleton-cards-wrapper">
      <div className="skeleton-cards">
        {Array(6)
          .fill(0)
          .map((_, index) => (
            <SkeletonCard key={index} />
          ))}
      </div>
      <Shimmer />
    </div>
  );
};

export default SkeletonCards;
