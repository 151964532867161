import React from "react";
import { Link } from "react-router-dom";

import Background from "../components/Background";
import Header from "../components/Header";

import Waving_Hand from "../assets/images/Waving_Hand.png";

import { ReactComponent as Devices } from "../assets/images/Devices.svg";
import { ReactComponent as Sort } from "../assets/images/Sort.svg";
import { ReactComponent as Find } from "../assets/images/Find.svg";

const Welcome = () => {
  return (
    <>
      <Background className="back" />
      <Header color="black" />
      <div className="welcome__title">
        <h1>Привіт</h1>
        <img src={Waving_Hand} alt="Waving hand" />
      </div>
      <div className="welcome__container">
        <Devices />
        <div className="welcome__descriptions">
          <h2 className="welcome__heading">
            Мрієш стати IT-шником?
            <br />
            Тоді тобі до нас.
          </h2>
          <h3 className="welcome__faculty">
            Факультет інформаційних <br />
            технологій та комп’ютерної <br />
            інженерії
          </h3>
        </div>
      </div>
      <div className="welcome__links">
        <Link to="/specialities">
          <button className="welcome__specialties">
            <p>Спеціальності</p> <Sort />
          </button>
        </Link>
        <Link to="/find">
          <button className="welcome__finder">
            <p>Пошук професії</p> <Find />
          </button>
        </Link>
      </div>
    </>
  );
};

export default Welcome;
