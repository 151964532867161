import React from "react";

const NumberBlock = ({ value, description }) => {
  return (
    <div className="numbers__block">
      <h1>{value}</h1>
      <p>{description}</p>
    </div>
  );
};

export default NumberBlock;
