import React from "react";

import { ReactComponent as Facebook } from "../../assets/socials/Facebook.svg";
import { ReactComponent as Instagram } from "../../assets/socials/Instagram.svg";
import { ReactComponent as LinkedIn } from "../../assets/socials/LinkedIN.svg";
import { ReactComponent as Telegram } from "../../assets/socials/Telegram.svg";
import { ReactComponent as Twitter } from "../../assets/socials/Twitter.svg";
import { ReactComponent as Youtube } from "../../assets/socials/Youtube.svg";

import { ReactComponent as Phone } from "../../assets/socials/phone.svg";
import { ReactComponent as Message } from "../../assets/socials/message.svg";

const DetailedLinks = ({ detailData }) => {
  const { socialNetworks, contacts, references } = detailData;

  // const socialNetworks = {
  //   facebook: "https://www.google.com/",
  //   instagram: "https://www.google.com/",
  //   telegram: "https://www.google.com/",
  //   twitter: "https://www.google.com/",
  //   youtube: "https://www.google.com/",
  //   linkedin: "https://www.google.com/",
  // };
  // const references = [
  //   {
  //     WebsiteName: "Сайт кафедри OT",
  //     WebsiteURL: "https://vntu.edu.ua/",
  //   },
  //   {
  //     WebsiteName: "Освітньо-професійні програми спеціальностей 123КІ",
  //     WebsiteURL: "https://vntu.edu.ua/",
  //   },
  //   {
  //     WebsiteName: "Перелік дисциплін 123КІ",
  //     WebsiteURL: "https://vntu.edu.ua/",
  //   },
  // ];

  // const contacts = {
  //   phone: "+ 38 (093) 186 03 99",
  //   email: "ot.vntu@gmail.com",
  // };
  return (
    <div className="detailed__links">
      <div className="detailed__linksContainer">
        <div className="detailed__usefulLinks">
          <h3>Посилання за спеціальністю</h3>
          <div className="detailed__usefulLinks-links">
            {references.map((item, index) => {
              return (
                <a key={`${item}_${index}`} href={item.facultyWebsiteURL}>
                  {item.facultyWebsiteName}
                </a>
              );
            })}
          </div>
        </div>
        <div className="detailed__responsibleLinks">
          <h3>Контакти відповідального за вступ</h3>
          <div className="detailed__responsibleLinks-contacts">
            <p>
              <Phone />
              <span>{contacts.phone}</span>
            </p>
            <p>
              <Message />
              <span>{contacts.email}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="detailed__socials">
        <h3>Ми у соціальних мережах</h3>
        <div className="detailed__socials-icons">
          <a href={socialNetworks.facebook}>
            <Facebook />
          </a>
          <a href={socialNetworks.telegram}>
            <Telegram />
          </a>
          <a href={socialNetworks.instagram}>
            <Instagram />
          </a>
          <a href={socialNetworks.twitter}>
            <Twitter />
          </a>
          <a href={socialNetworks.youtube}>
            <Youtube />
          </a>
          <a href={socialNetworks.linkedin}>
            <LinkedIn />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DetailedLinks;
