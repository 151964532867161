const specialities = [
  {
    id: "software-engineering",
    name: "Інженерія програмного забезпечення",
    faculty: "Програмної інженерії",
    pictureName: "SoftwareEngineering",
    cipher: 121,
    colorName: "blue",
    mainColor: "#ffffff",
    colorSchemePrimary: {
      color1: "#689F63",
      color2: "#3CE02D",
    },
    colorSchemeSecondary: {
      color1: "#00CFFD",
      color2: "#79FF63",
    },
    description:
      "Студенти спеціальності 121 Інженерія програмного забезпечення поглиблено вивчають процеси промислової розробки програмного забезпечення і отримують професійні компетентності, необхідні для проектування, розробки, забезпечення якості і супроводу програмного забезпечення для різноманітних інформаційних систем і платформ.",
    undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "160",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "4 роки",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік",
        numberValue: "170.4",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "67",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "26 800 ₴",
      },
      {
        numberTitle:
          "середній бал",
        numberValue: "155.5",
      },
    ],

    masters: [
      {
        numberTitle: "ліцензійна кількість місць (денна)",
        numberValue: "65",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "1рік 4 міс",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "166.6",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "42",
      },
      {
        numberTitle: "вартість одного року навчання (денна) (грн)",
        numberValue: "37 500 ₴",
      },
      {
        numberTitle: "середній бал",
        numberValue: "162",
      },
    ],

    jr_undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "0",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "0",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "0",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "0",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "0",
      },
      {
        numberTitle: "середній бал",
        numberValue: "0",
      },
    ],
    questions: [
      {
        question: "Де ознайомитись з Правилами вступу?",
        answer:
          "На сайті приймальної комісії ВНТУ https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        question: "Де дізнатись про кількість поданих заяв?",
        answer:
          "Конкурсні пропозиції системи ЄДБО https://vstup.edbo.gov.ua/, Інформаційна система Конкурс https://www.vstup.info/",
      },
      {
        question: "На якій спеціальності вивчають більше програмування/ІТ?",
        answer:
          "В однаковій мірі: спеціальності факультету відрізняються не глибиною вивчення програмування/ІТ, а радше кутами зору, з яких програмування та ІТ розглядаються.",
      },
      {
        question: "Яку роль відіграє вибір пріоритету спеціальності?",
        answer:
          "Система зараховує на бюджет на спеціальність із найвищим пріоритетом, серед тих, на які проходить абітурієнт. Якщо абітурієнт пройшов на бюджет на певну спеціальність, то на спеціальності, де було зазначено нижчий пріоритет, потрапити можливо лише на контракт.",
      },
      {
        question:
          "Яким предметам слід приділяти увагу в школах, щоб поступити на IT спеціальності ВНТУ і успішно на них навчатись?",
        answer:
          "Слід добре вчити інформатику, математику і українську мову. Однак, також є сенс слідкувати за Правилами прийому.",
          /*Цього року третє ЗНО можна обирати з наступних дисциплін: Іноземна мова, 
          Історія України, Біологія, Географія, Фізика, Хімія. Тому краще обрати третім 
          ЗНО ту дисципліну, до якої є хист і ЗНО з якої можна здати краще.*/
      },
    ],
    references: [
      {
        facultyWebsiteName: "Сайт кафедри ПЗ",
        facultyWebsiteURL: "http://pz.vntu.edu.ua/",
      },
      {
        facultyWebsiteName: "ОПП бакалаврів ІПЗ",
        facultyWebsiteURL:
          "http://pz.vntu.edu.ua/media/uploads/metod/program/opp121b.pdf",
      },
      {
        facultyWebsiteName: "Дисципліни бакалаврів ІПЗ",
        facultyWebsiteURL: "http://pz.vntu.edu.ua/work/sylabus",
      },
      {
        facultyWebsiteName: "ОПП магістрів ІПЗ",
        facultyWebsiteURL:
          "http://pz.vntu.edu.ua/media/uploads/metod/program/opp2021m.pdf",
      },
      {
        facultyWebsiteName: "Дисципліни магістрів ІПЗ",
        facultyWebsiteURL: "http://pz.vntu.edu.ua/work/sylabus",
      },
      {
        facultyWebsiteName: "Правила вступу",
        facultyWebsiteURL: "https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        facultyWebsiteName: "Конкурсні пропозиції системи ЄДБО",
        facultyWebsiteURL: "https://vstup.edbo.gov.ua/",
      },
    ],
    contacts: {
      phone: "+380 (96) 678 33 57",
      email: "pz.vntu@gmail.com",
    },
    socialNetworks: {
      facebook: "https://www.facebook.com/pz.vntu",
      instagram: "/",
      telegram: "https://t.me/pzvntu2020",
      twitter: "/",
      youtube: "/",
      linkedin: "/",
    },
  },
  {
    id: "computer-engineering",
    name: "Комп’ютерна інженерія",
    faculty: "Обчислювальної техніки",
    pictureName: "ComputerEngineering",

    cipher: 123,
    mainColor: "#ffffff",
    colorName: "blue",
    colorSchemePrimary: {
      color1: "#427BFF",
      color2: "#A255FF",
    },
    colorSchemeSecondary: {
      color1: "#00CFFD",
      color2: "#A255FF",
    },
    description:
      "Фахівці з Комп’ютерної інженерії отримують комплексні та глибокі знання для вирішення завдань в галузі інформаційних технологій і комп’ютерної інженерії з аналізу, розробки, використання та впровадження апаратно-програмних засобів комп’ютерних систем та мереж, цифрових і мікропроцесорних пристроїв обчислювальної техніки.",
    undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "55",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "4 роки",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік  ",
        numberValue: "158.8",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "20",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "26 800 ₴",
      },
      
      {
        numberTitle:
          "середній бал",
        numberValue: "149",
      },
    ],

    masters: [
      {
        numberTitle: "ліцензійна кількість місць (денна)",
        numberValue: "50",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "1рік 4 міс",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "160.2",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "29",
      },
      {
        numberTitle: "вартість одного року навчання (денна) (грн)",
        numberValue: "37 500 ₴",
      },
      {
        numberTitle: "середній бал",
        numberValue: "157.4",
      },
    ],

    jr_undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць (денна / заочна)",
        numberValue: "24 / 9",
      },
      {
        numberTitle: "термін навчання (денна / заочна)",
        numberValue: "3 роки",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік (денна / заочна)",
        numberValue: "159.1 / 152.8",
      },
      {
        numberTitle: "бюджетні місця (денна / заочна)",
        numberValue: "20 / 7",
      },
      {
        numberTitle: "вартість одного року навчання (денна / заочна) (грн)",
        numberValue: "26 800 ₴  13 200 ₴",
      },
      {
        numberTitle: "середній бал (денна / заочна)",
        numberValue: "150.4 / 147.4",
      },
    ],
    questions: [
      {
        question: "Де ознайомитись з Правилами вступу?",
        answer:
          "На сайті приймальної комісії ВНТУ https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        question: "Де дізнатись про кількість поданих заяв?",
        answer:
          "Конкурсні пропозиції системи ЄДБО https://vstup.edbo.gov.ua/, Інформаційна система Конкурс https://www.vstup.info/",
      },
      {
        question: "На якій спеціальності вивчають більше програмування/ІТ?",
        answer:
          "В однаковій мірі: спеціальності факультету відрізняються не глибиною вивчення програмування/ІТ, а радше кутами зору, з яких програмування та ІТ розглядаються.",
      },
      {
        question: "Яку роль відіграє вибір пріоритету спеціальності?",
        answer:
          "Система зараховує на бюджет на спеціальність із найвищим пріоритетом, серед тих, на які проходить абітурієнт. Якщо абітурієнт пройшов на бюджет на певну спеціальність, то на спеціальності, де було зазначено нижчий пріоритет, потрапити можливо лише на контракт.",
      },
      {
        question:
          "Яким предметам слід приділяти увагу в школах, щоб поступити на IT спеціальності ВНТУ і успішно на них навчатись?",
        answer:
  
        "Слід добре вчити інформатику, математику і українську мову. Однак, також є сенс слідкувати за Правилами прийому.",
        /*Цього року третє ЗНО можна обирати з наступних дисциплін: Іноземна мова, 
        Історія України, Біологія, Географія, Фізика, Хімія. Тому краще обрати третім 
        ЗНО ту дисципліну, до якої є хист і ЗНО з якої можна здати краще.*/
      },
    ],
    references: [
      {
        facultyWebsiteName: "Сайт кафедри ОТ",
        facultyWebsiteURL: "https://ot.vntu.edu.ua/",
      },
      {
        facultyWebsiteName: "ОПП бакалаврів КІ",
        facultyWebsiteURL:
          "https://ot.vntu.edu.ua/images/documents/sait/op_bak_ki_2022.pdf",
      },
      {
        facultyWebsiteName: "Дисципліни бакалаврів КІ",
        facultyWebsiteURL:
          "https://ot.vntu.edu.ua/images/documents/sait/ok_ki-b.pdf",
      },
      {
        facultyWebsiteName: "ОПП магістрів КІ",
        facultyWebsiteURL:
          "https://ot.vntu.edu.ua/images/documents/sait/123-%D0%9A%D0%86-%D0%9E%D0%9F%D0%9F_%D0%BC%D0%B0%D0%B3%D1%96%D1%81%D1%82%D1%80_2023.pdf",
      },
      {
        facultyWebsiteName: "Дисципліни магістрів КІ",
        facultyWebsiteURL:
          "https://ot.vntu.edu.ua/images/documents/sait/ok_ki-m.pdf",
      },
            {
        facultyWebsiteName: "Правила вступу",
        facultyWebsiteURL: "https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        facultyWebsiteName: "Конкурсні пропозиції системи ЄДБО",
        facultyWebsiteURL: "https://vstup.edbo.gov.ua/",
      },
    ],
    contacts: {
      phone: "+ 38 (093) 186 03 99",
      email: "ot.vntu@gmail.com",
    },
    socialNetworks: {
      facebook: "https://facebook.com/ot.vntu",
      instagram: "/",
      telegram: "/",
      twitter: "/",
      youtube: "/",
      linkedin: "/",
    },
  },

  {
    id: "security-of-information-and-communication",
    name: "Безпека інформаційних і комунікаційних систем",
    faculty: "Захисту інформації",
    pictureName: "SecurityOfInformationAndCommunication",
    cipher: 125,
    colorName: "purple",
    mainColor: "#ffffff",
    colorSchemePrimary: {
      color1: "#680DD4",
      color2: "#FF7E55",
    },
    colorSchemeSecondary: {
      color1: "#CC6969",
      color2: "#A255FF",
    },
    description:
      "Студенти освітньої програми Безпека інформаційних і комунікаційних систем спеціальності 125 Кібербезпека вивчають разом з класичним набором дисциплін підготовки кіберзахисника низку спеціалізованих дисциплін, направлених на програмування та адміністрування засобів для захисту мереж від хакерських атак.",
    undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "45",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "4 роки",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік  ",
        numberValue: "160.2",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "14",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "26 800 ₴",
      },
      {
        numberTitle:
          "середній бал ",
        numberValue: "149",
      },
    ],

    masters: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "30",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "1рік 4 міс",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "157",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "15",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "37 500  ₴",
      },
      {
        numberTitle: "середній бал",
        numberValue: "156.1",
      },
    ],

    jr_undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "0",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "0",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "0",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "0",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "0",
      },
      {
        numberTitle: "середній бал",
        numberValue: "0",
      },
    ],
    questions: [
      {
        question: "Де ознайомитись з Правилами вступу?",
        answer:
          "На сайті приймальної комісії ВНТУ https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        question: "Де дізнатись про кількість поданих заяв?",
        answer:
          "Конкурсні пропозиції системи ЄДБО https://vstup.edbo.gov.ua/, Інформаційна система Конкурс https://www.vstup.info/",
      },
      {
        question: "На якій спеціальності вивчають більше програмування/ІТ?",
        answer:
          "В однаковій мірі: спеціальності факультету відрізняються не глибиною вивчення програмування/ІТ, а радше кутами зору, з яких програмування та ІТ розглядаються.",
      },
      {
        question: "Яку роль відіграє вибір пріоритету спеціальності?",
        answer:
          "Система зараховує на бюджет на спеціальність із найвищим пріоритетом, серед тих, на які проходить абітурієнт. Якщо абітурієнт пройшов на бюджет на певну спеціальність, то на спеціальності, де було зазначено нижчий пріоритет, потрапити можливо лише на контракт.",
      },
      {
        question:
          "Яким предметам слід приділяти увагу в школах, щоб поступити на IT спеціальності ВНТУ і успішно на них навчатись?",
        answer:
          "Слід добре вчити інформатику, математику і українську мову. Однак, також є сенс слідкувати за Правилами прийому. Цього року третє ЗНО можна обирати з наступних дисциплін: Іноземна мова, Історія України, Біологія, Географія, Фізика, Хімія. Тому краще обрати третім ЗНО ту дисципліну, до якої є хист і ЗНО з якої можна здати краще.",
      },
    ],
    references: [
      {
        facultyWebsiteName: "Сайт кафедри ЗІ",
        facultyWebsiteURL: "https://zi.vntu.edu.ua",
      },
      {
        facultyWebsiteName: "ОПП бакалаврів БС",
        facultyWebsiteURL:
          "https://vntu.edu.ua/images/2020vstup/opp-bak-mag-2020/b_125_bezp_inf_i_kom_sist_opp.pdf",
      },
      {
        facultyWebsiteName: "Дисципліни бакалаврів",
        facultyWebsiteURL: "http://zi.vntu.edu.ua/discipline.html",
      },
      {
        facultyWebsiteName: "ОПП магістрів БС",
        facultyWebsiteURL:
          "https://vntu.edu.ua/images/2020vstup/opp-bak-mag-2020/m_125_bezp_inf_i_kom_sist_opp.pdf",
      },
      {
        facultyWebsiteName: "Дисципліни магістрів",
        facultyWebsiteURL: "http://zi.vntu.edu.ua/discipline.html",
      },
      {
        facultyWebsiteName: "Правила вступу",
        facultyWebsiteURL: "https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        facultyWebsiteName: "Конкурсні пропозиції системи ЄДБО",
        facultyWebsiteURL: "https://vstup.edbo.gov.ua/",
      },
    ],
    contacts: {
      phone: "+ 38 (093) 186 03 99",
      email: "kafedra.zi.vntu@gmail.com",
    },
    socialNetworks: {
      facebook: "/",
      instagram: "/",
      telegram: "https://t.me/vstupFitki_125Cybersec",
      twitter: "/",
      youtube: "/",
      linkedin: "/",
    },
  },
  
  {
    id: "computer-science",
    name: "Професійна освіта. Цифрові технології",
    faculty: "Кафедра безпеки життєдіяльності та педагогіки безпеки",
    pictureName: "SystemProgramming",
    cipher: "015.39",
    colorName: "red",
    mainColor: "#ffffff",
    colorSchemePrimary: {
      color1: "#680DD4",
      color2: "#FF7E55",
    },
    colorSchemeSecondary: {
      color1: "#CC6969",
      color2: "#A255FF",
    },
    description:
      "Освітня програма орієнтована на формування загальних і професійних компетентностей, потрібних для ефективного провадження педагогічної діяльності з викладання загально-технічних і спеціальних (фахових) дисциплін у галузі цифрових технологій; проектування та розробки педагогічних програмних засобів, інформаційного забезпечення навчального призначення; використання новітніх інформаційно-комунікаційних технологій в освітньому процесі.",
    undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "10",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "4 роки",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "-",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "-",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "24 800 ₴",
      },
      {
        numberTitle:
          "середній бал ",
        numberValue: "-",
      },
    ],

    masters: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "0",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "0",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "0",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "0",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "0",
      },
      {
        numberTitle: "середній бал",
        numberValue: "0",
      },
    ],

    jr_undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "0",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "0",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "0",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "0",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "0",
      },
      {
        numberTitle: "середній бал",
        numberValue: "0",
      },
    ],
    questions: [
      {
        question: "Де ознайомитись з Правилами вступу?",
        answer:
          "На сайті приймальної комісії ВНТУ https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        question: "Де дізнатись про кількість поданих заяв?",
        answer:
          "Конкурсні пропозиції системи ЄДБО https://vstup.edbo.gov.ua/, Інформаційна система Конкурс https://www.vstup.info/",
      },
      {
        question: "На якій спеціальності вивчають більше програмування/ІТ?",
        answer:
          "В однаковій мірі: спеціальності факультету відрізняються не глибиною вивчення програмування/ІТ, а радше кутами зору, з яких програмування та ІТ розглядаються.",
      },
      {
        question: "Яку роль відіграє вибір пріоритету спеціальності?",
        answer:
          "Система зараховує на бюджет на спеціальність із найвищим пріоритетом, серед тих, на які проходить абітурієнт. Якщо абітурієнт пройшов на бюджет на певну спеціальність, то на спеціальності, де було зазначено нижчий пріоритет, потрапити можливо лише на контракт.",
      },
      {
        question:
          "Яким предметам слід приділяти увагу в школах, щоб поступити на IT спеціальності ВНТУ і успішно на них навчатись?",
        answer:
        "Слід добре вчити інформатику, математику і українську мову. Однак, також є сенс слідкувати за Правилами прийому.",
        /*Цього року третє ЗНО можна обирати з наступних дисциплін: Іноземна мова, 
        Історія України, Біологія, Географія, Фізика, Хімія. Тому краще обрати третім 
        ЗНО ту дисципліну, до якої є хист і ЗНО з якої можна здати краще.*/
       },
    ],
    references: [
      {
        facultyWebsiteName: "Сайт кафедра Безпеки життєдіяльності та педагогіки безпеки",
        facultyWebsiteURL: "https://bgd.vntu.edu.ua/",
      },
      {
        facultyWebsiteName: "ОПП бакалаврів",
        facultyWebsiteURL:
          "https://iq.vntu.edu.ua/fm/fdb/796/ОПП_015_бакалавр__з_підписами_29.06.pdf",
      },
      {
        facultyWebsiteName: "Дисципліни бакалаврів",
        facultyWebsiteURL:
          "https://iq.vntu.edu.ua/fm/fdb/796/Перелік_компонент_освітньо.pdf",
      },
      {
        facultyWebsiteName: "Правила вступу",
        facultyWebsiteURL: "https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        facultyWebsiteName: "Конкурсні пропозиції системи ЄДБО",
        facultyWebsiteURL: "https://vstup.edbo.gov.ua/",
      },
      
    ],
    contacts: {
      phone: "+ 38 (097) 219 98 43",
      email: "dembitskasv@vntu.edu.ua",
    },
    socialNetworks: {
      facebook: "https://bgd.vntu.edu.ua/",
      instagram: "/",
      telegram: "/",
      twitter: "/",
      youtube: "/",
      linkedin: "/",
    },
  },
  {
    id: "system-programming",
    name: "Системне програмування",
    faculty: "Обчислювальної техніки",
    pictureName: "SystemProgramming",
    cipher: 123,
    colorName: "blue",
    mainColor: "#ffffff",
    colorSchemePrimary: {
      color1: "#427BFF",
      color2: "#A255FF",
    },
    colorSchemeSecondary: {
      color1: "#00CFFD",
      color2: "#A255FF",
    },
    description:
      "Фахівці з Системного програмування отримують глибокі та всебічні знання для вирішення завдань в галузі інформаційних технологій та комп’ютерної інженерії з розробки, використання та впровадження програмно-апаратних засобів комп’ютерних систем та мереж, їх системного та спеціалізованого програмного забезпечення.",
    undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "35",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "4 роки",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "158.3",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "20",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "26 800 ₴",
      },
      {
        numberTitle:
          "середній бал ",
        numberValue: "152.6",
      },
    ],

    masters: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "0",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "0",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "0",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "0",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "0",
      },
      {
        numberTitle: "середній бал",
        numberValue: "0",
      },
    ],

    jr_undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "0",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "0",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік  ",
        numberValue: "0",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "0",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "0",
      },
      {
        numberTitle: "середній бал",
        numberValue: "0",
      },
    ],
    questions: [
      {
        question: "Де ознайомитись з Правилами вступу?",
        answer:
          "На сайті приймальної комісії ВНТУ https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        question: "Де дізнатись про кількість поданих заяв?",
        answer:
          "Конкурсні пропозиції системи ЄДБО https://vstup.edbo.gov.ua/, Інформаційна система Конкурс https://www.vstup.info/",
      },
      {
        question: "На якій спеціальності вивчають більше програмування/ІТ?",
        answer:
          "В однаковій мірі: спеціальності факультету відрізняються не глибиною вивчення програмування/ІТ, а радше кутами зору, з яких програмування та ІТ розглядаються.",
      },
      {
        question: "Яку роль відіграє вибір пріоритету спеціальності?",
        answer:
          "Система зараховує на бюджет на спеціальність із найвищим пріоритетом, серед тих, на які проходить абітурієнт. Якщо абітурієнт пройшов на бюджет на певну спеціальність, то на спеціальності, де було зазначено нижчий пріоритет, потрапити можливо лише на контракт.",
      },
      {
        question:
          "Яким предметам слід приділяти увагу в школах, щоб поступити на IT спеціальності ВНТУ і успішно на них навчатись?",
        answer:
        "Слід добре вчити інформатику, математику і українську мову. Однак, також є сенс слідкувати за Правилами прийому.",
        /*Цього року третє ЗНО можна обирати з наступних дисциплін: Іноземна мова, 
        Історія України, Біологія, Географія, Фізика, Хімія. Тому краще обрати третім 
        ЗНО ту дисципліну, до якої є хист і ЗНО з якої можна здати краще.*/
       },
    ],
    references: [
      {
        facultyWebsiteName: "Сайт кафедри ОТ",
        facultyWebsiteURL: "https://ot.vntu.edu.ua/",
      },
      {
        facultyWebsiteName: "ОПП бакалаврів СП",
        facultyWebsiteURL:
          "https://ot.vntu.edu.ua/images/documents/sait/op_bak_sp_2022.pdf",
      },
      {
        facultyWebsiteName: "Дисципліни бакалаврів СП",
        facultyWebsiteURL:
          "https://ot.vntu.edu.ua/images/documents/sait/ok_sp-b.pdf",
      },
      {
        facultyWebsiteName: "ОПП магістрів КІ-СП",
        facultyWebsiteURL:
          "http://ot.vntu.edu.ua/images/documents/opp_mag_2020.pdf",
      },
      {
        facultyWebsiteName: "Дисципліни магістрів КІ-СП",
        facultyWebsiteURL:
          "http://ot.vntu.edu.ua/images/documents/opp_mag_comp_2020.pdf",
      },
      {
        facultyWebsiteName: "Правила вступу",
        facultyWebsiteURL: "https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        facultyWebsiteName: "Конкурсні пропозиції системи ЄДБО",
        facultyWebsiteURL: "https://vstup.edbo.gov.ua/",
      },
    ],
    contacts: {
      phone: "+ 38 (093) 186 03 99",
      email: "ot.vntu@gmail.com",
    },
    socialNetworks: {
      facebook: "https://facebook.com/ot.vntu",
      instagram: "/",
      telegram: "/",
      twitter: "/",
      youtube: "/",
      linkedin: "/",
    },
  },
  {
    id: "cybersecurity-of-critical-systems",
    name: "Кібербезпека критичних систем",
    faculty: "Захисту інформації",
    pictureName: "CybersecurityOfCriticalSystems",

    cipher: 125,
    colorName: "purple",
    mainColor: "#ffffff",
    colorSchemePrimary: {
      color1: "#680DD4",
      color2: "#FF7E55",
    },
    colorSchemeSecondary: {
      color1: "#CC6969",
      color2: "#A255FF",
    },
    description:
      "Студенти освітньої програми Кібербезпека критичних систем спеціальності 125 Кібербезпека вивчають разом з набором дисциплін підготовки кіберзахисника низку спеціалізованих дисциплін, направлених на адаптацію до умов роботи в сфері критичних інфрастуртур: банків, енергетичних систем та інших підприємств державного значення.",
    undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "20",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "4 роки",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік  ",
        numberValue: "159.7",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "10",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "26 800 ₴",
      },
      {
        numberTitle:
          "середній бал",
        numberValue: "149.5",
      },
    ],

    masters: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "0",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "0",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік ",
        numberValue: "0",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "0",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "0",
      },
      {
        numberTitle: "середній бал",
        numberValue: "0",
      },
    ],

    jr_undergraduate: [
      {
        numberTitle: "ліцензійна кількість місць",
        numberValue: "0",
      },
      {
        numberTitle: "термін навчання",
        numberValue: "0",
      },
      {
        numberTitle: "Прохідний бал на бюджет за попередній рік  ",
        numberValue: "0",
      },
      {
        numberTitle: "бюджетні місця ",
        numberValue: "0",
      },
      {
        numberTitle: "вартість одного року навчання (грн)",
        numberValue: "0",
      },
      {
        numberTitle: "середній бал",
        numberValue: "0",
      },
    ],
    questions: [
      {
        question: "Де ознайомитись з Правилами вступу?",
        answer:
          "На сайті приймальної комісії ВНТУ https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        question: "Де дізнатись про кількість поданих заяв?",
        answer:
          "Конкурсні пропозиції системи ЄДБО https://vstup.edbo.gov.ua/, Інформаційна система Конкурс https://www.vstup.info/",
      },
      {
        question: "На якій спеціальності вивчають більше програмування/ІТ?",
        answer:
          "В однаковій мірі: спеціальності факультету відрізняються не глибиною вивчення програмування/ІТ, а радше кутами зору, з яких програмування та ІТ розглядаються.",
      },
      {
        question: "Яку роль відіграє вибір пріоритету спеціальності?",
        answer:
          "Система зараховує на бюджет на спеціальність із найвищим пріоритетом, серед тих, на які проходить абітурієнт. Якщо абітурієнт пройшов на бюджет на певну спеціальність, то на спеціальності, де було зазначено нижчий пріоритет, потрапити можливо лише на контракт.",
      },
      {
        question:
          "Яким предметам слід приділяти увагу в школах, щоб поступити на IT спеціальності ВНТУ і успішно на них навчатись?",
          answer:
          "Слід добре вчити інформатику, математику і українську мову. Однак, також є сенс слідкувати за Правилами прийому.",
          /*Цього року третє ЗНО можна обирати з наступних дисциплін: Іноземна мова, 
          Історія України, Біологія, Географія, Фізика, Хімія. Тому краще обрати третім 
          ЗНО ту дисципліну, до якої є хист і ЗНО з якої можна здати краще.*/
        },
    ],
    references: [
      {
        facultyWebsiteName: "Сайт кафедри ЗІ",
        facultyWebsiteURL: "https://zi.vntu.edu.ua",
      },
      {
        facultyWebsiteName: "ОПП бакалаврів ККС",
        facultyWebsiteURL:
          "https://vntu.edu.ua/images/2020vstup/opp-bak-mag-2020/b_125_kibbezp_krit_sist_opp.pdf",
      },
      {
        facultyWebsiteName: "Дисципліни бакалаврів",
        facultyWebsiteURL: "http://zi.vntu.edu.ua/discipline.html",
      },
      {
        facultyWebsiteName: "Правила вступу",
        facultyWebsiteURL: "https://vstup.vntu.edu.ua/pravyla-pryiomu",
      },
      {
        facultyWebsiteName: "Конкурсні пропозиції системи ЄДБО",
        facultyWebsiteURL: "https://vstup.edbo.gov.ua/",
      },
    ],
    contacts: {
      phone: "+ 38 (093) 186 03 99",
      email: "kafedra.zi.vntu@gmail.com",
    },
    socialNetworks: {
      facebook: "/",
      instagram: "/",
      telegram: "https://t.me/vstupFitki_125Cybersec",
      twitter: "/",
      youtube: "/",
      linkedin: "/",
    },
  },
];

export default specialities;
