import React from "react";

const SkeletonElement = () => {
  return (
    <div className="skeleton-card">
      <div className="skeleton-card__titles">
        <div className="skeleton-card__title"></div>
        <div className="skeleton-card__title"></div>
      </div>
      <div className="skeleton-card__faculties">
        <div className="skeleton-card__faculty"></div>
        <div className="skeleton-card__faculty"></div>
        <div className="skeleton-card__faculty"></div>
      </div>
      <div className="skeleton-card__button">
        <div></div>
        <p></p>
      </div>
    </div>
  );
};

export default SkeletonElement;
