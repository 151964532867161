import React from "react";
//import { Switch, Route, Redirect } from "react-router-dom";
import { Switch, Route, Link } from "react-router-dom";

import "./scss/app.scss";

import Welcome from "./pages/Welcome";
import Specialties from "./pages/Specialties";
import DetailedPage from "./pages/DetailedPage";
import DetailedTitle from "./pages/sections/DetailedTitle";
import DetailedNumbers from "./pages/sections/DetailedNumbers";
import DetailedFAG from "./pages/sections/DetailedFAG";
import DetailedLinks from "./pages/sections/DetailedLinks";

import Developing from "./components/Developing";
//import Sandbox from "./components/Tabs/Sandbox";

{
  /* <Switch>
<Route path="/" exact component={Welcome}></Route>

<Route path="/quiz-creator" component={QuizCreator}></Route>
<Route path="/logout" component={Logout}></Route>
<Route path="/" exact component={QuizList}></Route>
<Redirect to="/" />
</Switch> */
}

function App() {
  return (
    <div className="wrapper">
      <div className="detailed">{/* <DetailedPage /> */}</div>

      <Switch>
        <Route path="/" exact>
          <Welcome />
        </Route>
        <Route path="/specialities">
          <Specialties />
        </Route>
        <Route path="/computer-engineering">
          <DetailedPage id="computer-engineering" />
        </Route>

        <Route path="/software-engineering">
          <DetailedPage id="software-engineering" />
        </Route>

        <Route path="/system-programming">
          <DetailedPage id="system-programming" />
        </Route>

        <Route path="/computer-science">
          <DetailedPage id="computer-science" />
        </Route>

        <Route path="/security-of-information-and-communication">
          <DetailedPage id="security-of-information-and-communication" />
        </Route>

        <Route path="/cybersecurity-of-critical-systems">
          <DetailedPage id="cybersecurity-of-critical-systems" />
        </Route>
        <Route path="/find">
          <Developing />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
