import React from "react";

import { ReactComponent as Bar } from "../assets/images/Bar.svg";

import { ReactComponent as VNTUIcon } from "../assets/images/Vntu_logo.svg";
import { ReactComponent as FITKIIcon } from "../assets/images/Fitki_logo.svg";
//import { ReactComponent as Bar } from "../assets/images/Bar.svg";
//import Bar from "./Bar";
import MenuToggle from "./Navigation/MenuToggle";
import Drawer from "./Navigation/Drawer";

const Header = ({ name, color }) => {
  const [menu, setMenu] = React.useState(false);
  const toggleMenuHandler = () => {
    setMenu(!menu);
  };

  const menuCloseHandler = () => {
    setMenu(false);
  };
  return (
    <>
      <Drawer
        onToggle={toggleMenuHandler}
        isOpen={menu}
        onClose={menuCloseHandler}
      />
      <div className="header">
        <MenuToggle
          onToggle={toggleMenuHandler}
          isOpen={menu}
          color={color}
        ></MenuToggle>
        {name && (
          <p className={`header__name ${color ? `header__name-${color}` : ""}`}>
            {name}
          </p>
        )}
        <div className="header__logos">
          <a href="http://initki.vntu.edu.ua/" className="header__fitki-logo">
            <FITKIIcon />
          </a>
          <a href="https://vntu.edu.ua/" className="header__vntu-logo">
            <VNTUIcon />
          </a>
        </div>
      </div>
    </>
  );
};

export default Header;
