import React from "react";
import Toggle from "../../components/Toggle";
import ScrollDown from "../../components/ScrollDown";

const DetailedFAG = ({ detailData }) => {
  const { questions } = detailData;

  const fag = [
    {
      question: "question1",
      answer: "answer2",
    },
    {
      question: "question1",
      answer: "answer2",
    },
    {
      question: "question1",
      answer: "answer2",
    },
    {
      question: "question1",
      answer: "answer2",
    },
    {
      question: "question1",
      answer: "answer2",
    },
  ];

  return (
    <div className="detailed__fag">
      <h2>FAQ</h2>
      <div className="detailed__fagContainer">
        {questions &&
          questions.map((item, index) => {
            return (
              <Toggle key={index} title={item.question}>
                <div className="detailed__fagContainer-answer">
                  <p>{item.answer}</p>
                </div>
              </Toggle>
            );
          })}
      </div>
      <ScrollDown title="посилання та контакти кафедри" />
    </div>
  );
};

export default DetailedFAG;
