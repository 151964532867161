import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Card from "../components/Card";
import SkeletonCard from "../components/Skeletons/SkeletonCard";
import SkeletonCards from "../components/Skeletons/SkeletonCards";

import specialities1 from "../storage.js";

const Specialties = () => {
  const [specialities, setSpecialities] = React.useState(null);

  React.useEffect(() => {
    setTimeout(() => {
      setSpecialities(specialities1);
    }, 1500);

    // axios
    //   .get("/specialities")
    //   .then((response) => {
    //     setSpecialities(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }, []);

  return (
    <>
      <Header color="black" name="Наші ІТ-спеціальності та освітні програми" />
      <div className="specialties__cards">
        {specialities ? (
          specialities.map((item, key) => {
            return (
              <Link to={item.id}>
                <Card
                  name={item.name}
                  faculty={item.faculty}
                  cipher={item.cipher}
                  color={item.colorName}
                  textColor={item.textColor}
                ></Card>
              </Link>
            );
          })
        ) : (
          <>
            <SkeletonCards />
          </>
        )}
      </div>
    </>
  );
};

export default Specialties;
