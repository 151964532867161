import React, { useState } from "react";

const Toggle = ({ children, title }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <section onClick={() => setToggle(!toggle)}>
      <span>{title}</span>
      {toggle ? children : ""}
    </section>
  );
};

export default Toggle;
