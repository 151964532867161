import React, { useState, useCallback } from "react";
import DetailedBackground from "../../components/DetailedBackground";
import Header from "../../components/Header";
import ScrollDown from "../../components/ScrollDown";

import PictureFaculty from "../../components/PictureFaculty";

//import { ReactComponent as ComputerEngineering } from "../../assets/images/ComputerEngineering.svg";

const Detailed = ({ detailData }) => {
  const {
    pictureName,
    name,
    colorSchemePrimary,
    colorSchemeSecondary,
    description,
  } = detailData;

  const [nameIcon, setNameIcon] = useState("ComputerEngineering");

  const handleOnCompleted = useCallback(
    (iconName) => console.log(`${iconName} successfully loaded`),
    []
  );

  const handleIconError = useCallback((err) => console.error(err.message), []);

  return (
    <div className="detailed__title">
      <Header name={name} color="white" />
      <DetailedBackground
        colorSchemePrimary={colorSchemePrimary}
        colorSchemeSecondary={colorSchemeSecondary}
      />
      <div className="detailed__greetings">
        <div>
          <PictureFaculty
            name={pictureName}
            onCompleted={handleOnCompleted}
            onError={handleIconError}
          />
        </div>
        <p className="detailed__description">{description}</p>
      </div>
      <ScrollDown title="cкрольте вниз для детальнішої інформації" />
    </div>
  );
};

export default Detailed;
