import React from "react";

const svgBackground = ({ className }) => {
  const colors = {
    color1: "#ffffff",
    color2: "#000000",
  };
  const { color1, color2 } = colors;
  console.log(window.innerHeight);
  return (
    <svg
      className={`welcome__background ${className}`}
      width={window.innerWidth}
      height={window.innerHeight}
      viewBox={`0 0 ${window.innerWidth} ${window.innerHeight * 0.97}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M186.579 256.986C192.62 -443.411 689.352 -652.99 1398.82 -307.283C1815.71 -104.142 1919.85 -234.834 1916.3 176.726C1910.26 877.122 1524.83 1068.19 815.363 722.481C398.474 519.34 183.03 668.546 186.579 256.986Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M134.653 -34.0672C400.079 -696.37 948.676 -759.262 1493.04 -237.61C1812.92 68.9178 1960.1 -26.5067 1804.13 362.669C1538.71 1024.97 1102.49 1100.74 558.119 579.087C238.244 272.558 -21.314 355.109 134.653 -34.0672Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-150"
          y1="-81.2477"
          x2="1979.48"
          y2="-640.589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00CFFD" />
          <stop offset="1" stop-color="#A255FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="-59"
          y1="-446.706"
          x2="2141.88"
          y2="-183.855"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3A13B8" />
          <stop offset="1" stop-color="#A255FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default svgBackground;
