import React from "react";
import axios from "axios";

import DetailedFAG from "./sections/DetailedFAG";
import DetailedLinks from "./sections/DetailedLinks";
import DetailedNumbers from "./sections/DetailedNumbers";
import DetailedTitle from "./sections/DetailedTitle";

import specialities1 from "../storage.js";

const DetailedPage = ({ name, id }) => {
  const [detailData, setDetailData] = React.useState(null);

  React.useEffect(() => {
    const findProperFaculy = (name) => {
      return specialities1.map((item) => {
        if (item.id === id) {
          console.log(item);
          setDetailData(item);
        }
      });
    };

    findProperFaculy();
    // axios
    //   .get(`/specialities/${id}`)
    //   .then((response) => {
    //     // handle success
    //     setDetailData(response.data);
    //     //setSpecialities(response.data);
    //   })
    //   .catch((error) => {
    //     // handle error
    //     console.log(error);
    //   });
  }, []);

  return (
    <>
      {detailData ? (
        <>
          <DetailedTitle detailData={detailData} />
          <DetailedNumbers detailData={detailData} />
          <DetailedFAG detailData={detailData} />
          <DetailedLinks detailData={detailData} />
        </>
      ) : (
        <h1> waiting...</h1>
      )}
    </>
  );
};

export default DetailedPage;
