import React from "react";
import { Tabs, useTabState, Panel } from "../helpers/tabs.jsx";

const cn = (...args) => args.filter(Boolean).join(" ");

const Tab = React.memo(function Tab({ children, className }) {
  const { isActive, onClick } = useTabState();

  return (
    <li className={cn(isActive && "active")} onClick={onClick}>
      {children}
    </li>
  );
});

const Categories = React.memo(function Categories({
  categories,
  onClickCategory,
  activeCategory,
}) {
  console.log(activeCategory);
  return (
    <ul className="categories">
      {categories &&
        categories.map((item, index) => {
          return (
            <Tab
              className={activeCategory === index ? "active" : ""}
              onClick={() => onClickCategory(index)}
              key={`${item}_${index}`}
            >
              {item}
            </Tab>
          );
        })}
    </ul>
  );
});

export default Categories;

// import React from "react";
// import { Tabs, useTabState, Panel } from "../helpers/tabs.jsx";

// const cn = (...args) => args.filter(Boolean).join(" ");

// const Tab = ({ children, className }) => {
//   const { isActive, onClick } = useTabState();
//   return (
//     <li className={cn(isActive && "active")} onClick={onClick}>
//       {children}
//     </li>
//   );
// };

// const Categories = ({ categories, onClickCategory, activeCategory }) => {
//   const { isActive, onClick } = useTabState();

//   const onClickLi = (index) => {
//     onClickCategory(index);
//     onClick();
//   };
//   return (
//     <ul className="categories">
//       {categories &&
//         categories.map((item, index) => {
//           return (
//             <li
//               className={cn(isActive && "active")}
//               onClick={() => onClickLi(index)}
//               key={`${item}_${index}`}
//             >
//               {item}
//             </li>
//           );
//         })}
//     </ul>
//   );
// };

// export default Categories;
