import React from "react";
import Categories from "../../components/Categories";
import NumberBlock from "../../components/NumberBlock";
import ScrollDown from "../../components/ScrollDown";

import { Tabs, useTabState, Panel } from "../../helpers/tabs.jsx";

const item = (
  <>
    <span>Бакалаврат</span> <p>(на осн. коледжу)</p>
  </>
);

const categoriesLabels = ["Бакалаврат", "Магістратура", item];

const DetailedNumbers = ({ detailData }) => {
  const { undergraduate, jr_undergraduate, masters } = detailData;

  const [category, setCategory] = React.useState(0);

  const onSelectCategory = React.useCallback((index) => {
    setCategory(index);
  }, []);

  return (
    <>
      <Tabs>
        <div className="detailed__numbers">
          <Categories
            activeCategory={category}
            categories={categoriesLabels}
            onClickCategory={onSelectCategory}
          />
          <div className="detailed__numberBlocks">
            <Panel>
              {undergraduate.map((item, key) => {
                return (
                  <NumberBlock
                    key={key}
                    value={item.numberValue}
                    description={item.numberTitle}
                  />
                );
              })}
            </Panel>
            <Panel>
              {masters.map((item, key) => {
                return (
                  <NumberBlock
                    key={key}
                    value={item.numberValue}
                    description={item.numberTitle}
                  />
                );
              })}
            </Panel>
            <Panel>
              {jr_undergraduate.map((item, key) => {
                return (
                  <NumberBlock
                    key={key}
                    value={item.numberValue}
                    description={item.numberTitle}
                  />
                );
              })}
            </Panel>
          </div>
          <ScrollDown title="поширені запитання і відповіді щодо вступу" />
        </div>
      </Tabs>
    </>
  );
};

export default DetailedNumbers;
