import React from "react";
//import Backdrop from "../../UI/Backdrop/Backdrop";
import { Link } from "react-router-dom";
import Backdrop from "../UI/Backdrop";
import { ReactComponent as Close } from "../../assets/images/Close.svg";
import { ReactComponent as Stock } from "../../assets/images/Stock.svg";
import { ReactComponent as Search_alt } from "../../assets/images/Search_alt.svg";
import { ReactComponent as Pressure } from "../../assets/images/Pressure.svg";
import { ReactComponent as Chart } from "../../assets/images/Chart.svg";
import { ReactComponent as SignIn } from "../../assets/images/Sign_in_circle.svg";

const Drawer = ({ isOpen, onClose, onToggle }) => {
  const clickHandler = () => {
    onClose();
  };

  const links = [
    {
      to: "/",
      label: "Головна сторінка",
      icon: <Chart />,
      exact: true,
    },
    {
      to: "/specialities",
      label: "Спеціальності",
      icon: <Stock />,
      exact: true,
    },
    {
      to: "/find",
      label: "Пошук професії",
      icon: <Search_alt />,
      exact: true,
    },
    // {
    //   to: "/",
    //   label: "Порівняння",
    //   icon: <Pressure />,
    //   exact: true,
    // },
    // {
    //   to: "/",
    //   label: "Про нас",
    //   icon: <Chart />,
    //   exact: true,
    // },
    {
      to: "/find",
      label: "Вхід у профіль",
      icon: <SignIn />,
      exact: true,
    },
  ];

  // if (isAuthenticated) {
  //   links.push({
  //     to: "/quiz-creator",
  //     label: "Create a quiz",
  //     exact: false,
  //   });

  //   links.push({
  //     to: "/logout",
  //     label: "Log Out",
  //     exact: false,
  //   });
  // } else {
  //   links.push({
  //     to: "/auth",
  //     label: "Sign In",
  //     exact: false,
  //   });
  // }

  const renderLinks = () => {
    return links.map((link, key) => {
      return (
        <Link key={`${link}_${key}`} to={link.to}>
          <li className="Drawer__item">
            <div>{link.icon}</div>
            <p> {link.label}</p>
            {/* <Link
            to={link.to}
            exact={link.exact.toString()}
            activeclassname={"active"}
            onClick={clickHandler}
          >
            {link.label}
          </Link> */}
          </li>
        </Link>
      );
    });
  };

  return (
    <>
      <nav className={`Drawer ${!isOpen ? "close" : ""}`}>
        <div className="Drawer__controls">
          <Close onClick={onToggle} />
          {/* <Close onClick={onToggle} /> */}
        </div>
        <ul>{renderLinks()}</ul>
      </nav>
      {isOpen && <Backdrop onClick={onClose} />}
    </>
  );
};

export default Drawer;
