import React from "react";
// import Bar from "../../assets/images/Bar.svg";
import { ReactComponent as Bar } from "../../assets/images/Bar.svg";
import { ReactComponent as BarWhite } from "../../assets/images/Bar-white.svg";
// const Bar = () => (
//   <svg
//     width="50"
//     height="45"
//     viewBox="0 0 50 45"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <g filter="url(#filter0_b)">
//       <rect width="50" height="7.5" rx="3.75" fill={exactColor} />
//       <rect y="18.3333" width="50" height="7.5" rx="3.75" fill={exactColor} />
//       <rect y="36.6666" width="50" height="7.5" rx="3.75" fill={exactColor} />
//     </g>
//     <defs>
//       <filter
//         id="filter0_b"
//         x="-30"
//         y="-30"
//         width="110"
//         height="104.167"
//         filterUnits="userSpaceOnUse"
//         color-interpolation-filters="sRGB"
//       >
//         <feFlood flood-opacity="0" result="BackgroundImageFix" />
//         <feGaussianBlur in="BackgroundImage" stdDeviation="15" />
//         <feComposite
//           in2="SourceAlpha"
//           operator="in"
//           result="effect1_backgroundBlur"
//         />
//         <feBlend
//           mode="normal"
//           in="SourceGraphic"
//           in2="effect1_backgroundBlur"
//           result="shape"
//         />
//       </filter>
//     </defs>
//   </svg>
// );

const MenuToggle = ({ onToggle, color, isOpen }) => {
  //  const exactColor = `${color === "black" ? `#0A0D1F` : `#F2F6FF`}`;

  return (
    <span onClick={onToggle}>{color === "black" ? <Bar /> : <BarWhite />}</span>
  );
};

export default MenuToggle;
