import React from "react";

const DetailedBackground = ({
  className,
  colorSchemePrimary,
  colorSchemeSecondary,
}) => {
  return (
    <svg
      className={`detailed__background ${className}`}
      width={window.innerWidth * 0.996}
      height={window.innerHeight * 0.99}
      viewBox={`0 0 ${window.innerWidth * 0.98} ${window.innerHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-2.63032 -158.367C-13.0292 -268.333 67.6861 -365.909 177.652 -376.307L1965.33 -545.359C2075.3 -555.758 2172.87 -475.042 2183.27 -365.076L2260.45 451.091C2270.85 561.057 2190.14 658.632 2080.17 669.031L292.491 838.082C182.524 848.481 84.9491 767.766 74.5502 657.8L-2.63032 -158.367Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M0.204806 -2.05618C-26.8437 -109.15 38.0461 -217.894 145.14 -244.942L1886.12 -684.658C1993.22 -711.707 2101.96 -646.817 2129.01 -539.723L2329.76 255.124C2356.81 362.218 2291.92 470.962 2184.83 498.011L443.844 937.727C336.75 964.775 228.006 899.886 200.958 792.792L0.204806 -2.05618Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-21.4592"
          y1="-357.479"
          x2="2260.39"
          y2="693.341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorSchemeSecondary[`color1`]} />
          <stop offset="1" stopColor={colorSchemeSecondary[`color2`]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="-48.7708"
          y1="-195.967"
          x2="2366.64"
          y2="494.551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorSchemePrimary[`color1`]} />
          <stop offset="1" stopColor={colorSchemePrimary[`color2`]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DetailedBackground;
